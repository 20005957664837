<template>
  <div class="flex direction center register_page bgSea">
    <div class="form" v-show="!showSelectCounty && !showSelectCity">
      <div class="titleTop">Complete your profle</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.age"
            name="age"
            placeholder="Enter your age"
            @click="showTime = true"
          />
          <van-popup v-model:show="showTime" position="bottom">
            <van-date-picker
              :min-date="minDate"
              :max-date="maxDate"
              :formatter="formatter"
              @confirm="changeTime"
              @cancel="showTime = false"
              :columns-type="columnsType"
            />
          </van-popup>

          <div class="label">GENDER</div>
          <van-field class="radioItem" name="gender">
            <template #input>
              <van-radio-group
                v-model="form.gender"
                direction="horizontal"
                @click="isOkForm()"
              >
                <van-radio name="1">Male</van-radio>
                <van-radio name="2">Female</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="label">YOU COUNTRY</div>
          <van-field
            readonly
            name="country"
            v-model="form.country"
            placeholder="Select your country"
            @click="showPop(1)"
          />

          <div class="tips">
            {{
              isOk
                ? 'Your profile is complete'
                : 'You need to perfect your profile'
            }}
          </div>
          <div style="margin: 0.75rem">
            <van-button
              :class="isOk ? 'btnDone' : 'btnNo'"
              round
              block
              type="info"
              native-type="submit"
              >Enter</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
    <selectCounty
      v-if="showSelectCounty"
      @selectCountry="selectCountry"
      @closeCountry="closeCountry"
    ></selectCounty>
    <!-- <selectCity :country="this.country" v-if='showSelectCity' @selectCity="selectCity" @closeCity="closeCity"></selectCity> -->
  </div>
</template>

<script>
import { showToast } from 'vant'
import selectCounty from '../../components/Select/country.vue'
import selectCity from '../../components/Select/city.vue'
import cityData from '../../config/country/worldCItyData.js'
import { generateUniqueString } from '@/config/common/dic'
const appName = import.meta.env.VITE_APP_NAME
export default {
  components: { selectCounty, selectCity },
  data() {
    return {
      form: {
        age: '',
        gender: 1,
        country: '',
        countryCode: '',
      },
      columnsType: ['year', 'month'],
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      cityData: cityData,
      showSelectCounty: false,
      showSelectCity: false,
      showTime: false,
      isOk: false,
      city: '',
      password: '',
      value: '',
      reqFlag: false,
    }
  },
  created() {},
  mounted() {
    // document.addEventListener('touchmove', this.preventTouchScroll, { passive: false });
  },
  beforeUnmount() {},
  methods: {
    // preventTouchScroll(event) {
    //   event.preventDefault();
    // },
    onSubmit(values) {
      try {
        if (this.reqFlag) return
        this.reqFlag = true
        const __token__ = generateUniqueString()
        const url = new URL(window.location.href)
        const startapp = url.searchParams.get('startapp')
        // 将age字段转成年龄值
        let age = this.calculateAge(values.age)
        let data = {
          tgid:
            this.$g.getStorage('userId') ||
            localStorage.getItem(`${appName}_userId`),
          age: age,
          nation: this.form.countryCode,
          gender: Number(values.gender),
          inviter_tgid: startapp || '',
          __token__,
        }
        console.log(data, 'data')

        if (this.isOk) {
          // 调用注册接口
          this.$api
            .register(data)
            .then((res) => {
              this.reqFlag = false
              console.log(res)
              if (res.code === 0) {
                // 注册成功
                console.log('注册成功拿到的信息', res)
                this.$emit('success')
              } else {
                showToast('register error')
                console.log('register error')
              }
            })
            .catch((err) => {
              this.reqFlag = false
              showToast('register error')
              console.log('err=========================', err)
            })
        }
      } catch (error) {
        //
      }
    },
    // 判断用户年龄
    calculateAge(birthday) {
      // 将生日字符串转换为日期对象
      var birthDate = new Date(birthday)
      var today = new Date()
      // 计算年龄
      var age = today.getFullYear() - birthDate.getFullYear()
      // 检查是否已过生日
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--
      }
      return age
    },
    showPop(index) {
      if (index === 1) {
        this.showSelectCounty = true
      } else {
        if (this.country) {
          this.showSelectCity = true
        } else {
          showToast('请先选择国家')
        }
      }
    },
    selectCountry(item) {
      console.log('选择国家===============', item)

      this.form.country = item.name
      this.form.countryCode = item.code

      this.showSelectCounty = false
      this.isOkForm()
    },
    closeCountry() {
      this.showSelectCounty = false
      this.isOkForm()
    },
    changeTime(value) {
      this.showTime = false
      this.form.age = value.selectedValues.join('/')
      this.isOkForm()
    },
    // 判断form是否完整
    isOkForm() {
      let age = this.form.age
      let gender = this.form.gender
      let country = this.form.country
      if (age && gender && country) {
        this.isOk = true
      } else {
        this.isOk = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  overflow: scroll;
  min-height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.form {
  margin: 0 auto;
  width: 70%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 1.1rem;
}
.titleTop {
  font-size: 1rem;
  color: #39cce9;
  font-weight: 500;
  margin-bottom: 1rem;
}
.label {
  font-size: 0.7rem;
  display: flex;
  color: #8a9bb9;
  margin-top: 0.6rem;
  margin-bottom: 0.25rem;
}
.tips {
  font-size: 0.7rem;
  color: #39cce9;
  margin-top: 1rem;
}
.btnNo {
  width: 7rem;
  height: 2.5rem;
  background-color: #dee3ed;
  font-size: 0.9rem;
  color: #8a9bb9;
  margin: 0 auto;
}
.btnDone {
  width: 7rem;
  height: 2.5rem;
  background-color: #39cce9;
  font-size: 0.9rem;
  color: #ffffff;
  margin: 0 auto;
}
</style>
