<template>
  <div class="direction center shop_page bgSea">
    <div class="top">
      <div class="title">
        <div class="info">
          <div><img :src="imgs.bottle" alt="" />{{ remainBottleCount }}</div>
        </div>
        <div class="info">
          <div>
            <img style="width: 1rem; height: 1rem" :src="imgs.coin" alt="" />{{
              remainGold
            }}
          </div>
        </div>
        <!-- <div class="wallet">
          <img v-if="isWallet" class="walletLogo" :src="imgs.wallet" alt="" />
          <img v-else class="walletLogo" :src="imgs.wallet_null" alt="" />
        </div> -->
      </div>
      <div class="tab">
        <div
          @click="changeTab(index)"
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: activeTab === index }"
          :style="{ color: activeTab === index ? 'black' : '' }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!-- 导航锚点 -->
    <div ref="Gold" style="width: 100%; height: 1px"></div>
    <div
      class="listTitle"
      :style="{ 'background-image': `url(${imgs.title})` }"
    >
      Gold
    </div>
    <div class="list" style="padding-bottom: 0">
      <van-row :gutter="[20, 20]">
        <van-col v-for="item in goldList" :key="item.id" span="12">
          <div class="colBg">
            <div class="itemImg"><img :src="item.icon_url" alt="" /></div>
            <div class="itemCoin">
              <img :src="imgs.coin" alt="" />{{ item.item_num }}
            </div>
            <div class="itemPrice" @click="buy(0, item)">
              $ {{ item.amount }}
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <div
      class="listTitle bottleTitle"
      style="margin-top: 1rem"
      :style="{ 'background-image': `url(${imgs.title})` }"
    >
      <!-- 导航锚点 -->
      <div
        ref="Bottles"
        style="width: 100%; height: 1px; position: absolute; top: -6rem"
      ></div>
      Bottles
    </div>
    <div class="list">
      <van-row :gutter="[20, 20]">
        <van-col v-for="item in bottleList" :key="item.id" span="12">
          <div class="colBg">
            <div class="itemImg"><img :src="item.icon_url" alt="" /></div>
            <div class="bottle-num">x {{ item.item_num }}</div>
            <div class="itemCoin">{{ item.desc }}</div>
            <div class="itemPrice" @click="buy(1, item)">
              <img :src="imgs.coin" alt="" />{{ item.amount }}
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model:show="showNoMoney" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showNoMoney = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.buy_coin3" alt="" />
        <div class="sendTitle">Insufficient currency</div>
        <div class="sendContext">
          Please obtain sufficient currency<br />
          before making a purchase
        </div>
        <div class="sendBtn" @click="getMoney">Get it</div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="showOrderPending" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showOrderPending = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.buy_coin3" alt="" />
        <div class="sendTitle">Payment Status</div>
        <div class="sendContext">
          Have you completed the payment?<br />
          Please confirm to proceed.
        </div>
        <div class="sendBtn" @click="confirmPayment(true)">
          Yes, Payment Completed
        </div>
        <div class="sendBtn" @click="confirmPayment(false)">
          No, Payment Failed
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import flag from 'vue-country-flag-next'
import {
  userTotalInfo,
  todayBottleNumInfo,
  goodsListRef,
  getUserInitInfo,
  getUserTotalInfo,
  initParams,
} from '@/config/common/total'
import { showToast } from 'vant'
export default {
  components: { flag },
  data() {
    return {
      info: {
        ton: 10,
        coin: 5,
        bottle: 5,
      },
      showNoMoney: false,
      isWallet: true,
      imgs: this.$store.state.sourceMsg,
      activeTab: 0,
      tab: ['Gold', 'Bottles'],
      shopType: 0,
      orderId: '',
      orderPayStatus: 'completed',
      showOrderPending: false,
      onlyOnceHint: true,
    }
  },
  watch: {
    orderPayStatus(n, o) {
      if (n === 'pending') {
        this.showOrderPending = true
      }
    },
  },
  computed: {
    remainGold() {
      return initParams.value?.currency?.gold || userTotalInfo.value?.gold || 0
      // return 0
    },
    remainBottleCount() {
      const values = Object.values(todayBottleNumInfo.value)
      return values.reduce((a, b) => a + b, 0) || 0
    },
    goldList() {
      return goodsListRef.value
        .filter((item) => item?.shop_type === 1)
        .sort((a, b) => a.sort - b.sort)
    },
    bottleList() {
      return goodsListRef.value
        .filter((item) => item?.shop_type === 1004)
        .sort((a, b) => a.sort - b.sort)
    },
  },
  created() {
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/' })
    })
    document.getElementsByTagName('body')[0].style.backgroundColor = '#B2EFF9'
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  methods: {
    changeTab(index) {
      this.activeTab = index
      const gold = this.$refs.Gold
      const bottles = this.$refs.Bottles
      if (index === 0) {
        gold.scrollIntoView({ behavior: 'smooth' })
      } else if (index === 1) {
        bottles.scrollIntoView({ behavior: 'smooth' })
      }
    },
    async buy(index, item) {
      this.shopType = index
      try {
        const res = await this.getBuyStatus(item.id)
        if (index === 0) {
          // 处理支付逻辑
          const url = res?.payment_url
          this.orderId = res?.sn
          console.log(url, 'url->>>>>>>>>>>')
          if (url) {
            this.onlyOnceHint = true
            window.Telegram.WebApp.openTelegramLink(url)
            let timer = setTimeout(() => {
              clearTimeout(timer)
              this.orderPayStatus = 'pending'
            }, 2000)
          } else {
            this.orderPayStatus = 'pending'
            showToast(res?.message || 'pay failed')
          }
        } else {
          // 处理瓶子购买逻辑
          if (this.remainGold < item.amount) {
            this.showNoMoney = true
          } else {
            if (res === true) {
              await getUserInitInfo()
              showToast('Buy success')
              // getUserTotalInfo()
            } else {
              showToast('Buy failed')
            }
          }
        }
      } catch (error) {
        console.log(error, 'error->>>>>')
        showToast('An error occurred during the purchase.')
      }
    },
    // 发动订单和商城跳转
    async getBuyStatus(id) {
      try {
        const res = await this.$api.buyGood_Ajax({
          good_id: id,
          number: 1,
        })

        if (res.code === 0 && res.data?.status === 'success') {
          return true
        }
        return res.data?.status === 'pending' ? res.data : false
      } catch (error) {
        console.error('Failed to fetch buy status:', error)
        return false
      }
    },

    // 检查订单状态
    async checkOrderStatus() {
      try {
        const res = await this.$api.checkOrderStatus_Ajax(this.orderId)
        if (res?.code === 0 && res?.data?.status === 'success') {
          this.orderPayStatus = 'success'
          this.orderId = ''
          showToast('order pay success')
        }
      } catch (error) {
        if (this.onlyOnceHint) {
          this.onlyOnceHint = false
          showToast('Error checking order status')
        }
        this.showOrderPending = false
        console.error('Error checking order status:', error)
      }
    },
    // 弹窗确认
    async confirmPayment(success) {
      await this.checkOrderStatus()
      if (success) {
        if (this.orderPayStatus === 'success') {
          showToast('Payment Completed')
          getUserTotalInfo()
          this.$nextTick(() => {
            this.orderPayStatus === 'completed'
          })
        } else {
          let attempts = 0
          let maxAttempts = 10
          let timer = setInterval(async () => {
            attempts++
            if (attempts >= maxAttempts || this.orderPayStatus === 'success') {
              clearInterval(timer)
              this.orderPayStatus = 'completed'
            } else {
              await this.checkOrderStatus()
            }
          }, 1500)
        }
        this.showOrderPending = false
      } else {
        this.showOrderPending = false
        this.orderPayStatus === 'completed'
        getUserTotalInfo()
      }
    },

    getMoney() {
      this.showNoMoney = false
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  // height: 100vh;
  // background-color: #B2EFF9;
}
.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 4rem;
  background: #ffffff;
  width: 100%;
  .title {
    width: 90%;
    margin: 0 auto;
    font-size: 0.7rem;
    margin-bottom: 0.8rem;
    display: flex;
    font-weight: bold;
    .info {
      background-color: #edfafc;
      border-radius: 0.5rem;
      padding: 0 0.5rem;
      height: 1.65rem;
      display: flex;
      margin-right: 0.5rem;
      div {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 0.1rem;
        width: 0.8125rem;
        height: 1rem;
      }
    }
    .wallet {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      .walletLogo {
        width: 1.55rem;
        height: 1.7rem;
        position: absolute;
        left: -0.5rem;
      }
    }
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
}
.list {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 4rem;
  .colBg {
    position: relative;
    background-color: #fff;
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    .bottle-num {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      font-size: 0.7rem;
    }
  }
  .itemImg {
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #eafafc;
    padding: 0.7rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.3rem;
    img {
      width: 2.3rem;
    }
  }
  .itemCoin {
    padding: 0.5rem 0;
    font-size: 0.7rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.2rem;
    }
  }
  .itemPrice {
    background-color: #39cce9;
    color: #ffffff;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    font-size: 0.8rem;
    margin: 0 auto;
    width: 90%;
    img {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.3rem;
    }
  }
}
.listTitle {
  width: 7.7rem;
  height: 1.25rem;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 5.75rem;
  font-size: 0.85rem;
  color: #39cce9;
}
.bottleTitle {
  position: relative;
}
.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    margin: 1rem 0;
    width: 5.45rem;
    height: 2.75rem;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
</style>
