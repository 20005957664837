<template>
  <div class="direction center get_page bgSea">
    <div class="box">
      <div class="top"></div>
      <div class="editBack" :style="{ 'background-image': `url(${paper})` }">
        <div class="avatar">
          <img :src="avatarUrl" alt="" />
        </div>
        <div class="bottleInfo">
          <div class="infoTop">
            <div class="username">{{ userInfo.nickname }}</div>
            <div class="userage">
              <img
                :src="bottleInfo.gender === 0 ? imgs.men_full : imgs.women_full"
                alt=""
              />{{ userInfo.age }}
            </div>
          </div>
          <div class="infoBottom">
            <span> {{ $g.getCountryName(userInfo.nation) }},</span
            ><span>{{ formatDateFn(bottleInfo.send_time) }}</span>
          </div>
        </div>
        <div v-if="bottleInfo?.config?.url" class="textImg">
          <img :src="bottleInfo?.config?.url" alt="" />
        </div>
        <div
          class="textContent"
          :style="{ fontFamily: familyType }"
          v-html="formatText(bottleInfo?.content)"
        ></div>
      </div>
      <div class="bottomText">
        <div class="release" @click="handleRelease">
          <img :src="imgs.release" alt="" />Release
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '../../assets/img/test.png'
import { poolBottleInfo, bottleListRef } from '@/config/common/total'
import {
  formatDate,
  fontFamilyList,
  paperList,
  getAvatar,
} from '@/config/common/dic'
import { showToast } from 'vant'
const base_url = import.meta.env.VITE_BASE_URL
export default {
  components: {},
  data() {
    return {
      bottleInfo: {
        userName: 'Weile',
        gender: 0,
        age: 18,
        country: 'Canada',
        time: 'May 12th 2024',
        avatar: avatar,
      },
      imgs: this.$store.state.sourceMsg,
      url: '',
      formatDateFn: formatDate,
      fontFamilyList: [],
      paperList: [],
      userInfo: {},
    }
  },
  computed: {
    avatarUrl() {
      return getAvatar(this.userInfo?.tgid) || ''
    },
    paper() {
      return (
        this.paperList.find((item) => item.id == this.bottleInfo.config?.paper)
          ?.img || this.$store.state.sourceMsg.paper1
      )
    },
    familyType() {
      return (
        this.fontFamilyList.find(
          (item) => item.id === this.bottleInfo.config?.fontFamily
        )?.fontFamily || this.$store.state.sourceMsg.font1
      )
    },
  },
  created() {
    this.$g.isShowBackBtnClick('bottles')
    this.fontFamilyList = fontFamilyList()
    this.paperList = paperList()
    console.log(this.paperList, ' this.paperList')

    if (poolBottleInfo.value?.content) {
      this.bottleInfo = poolBottleInfo.value
      if (!this.bottleInfo?.config) {
        this.bottleInfo.config = { paper: 1, fontFamily: 0, url: '' }
      }
    }
    console.log(this.bottleInfo)
    this.userInfo = JSON.parse(this.$g.getStorage('userInfo'))
  },
  mounted() {
    this.readMessageFn()
  },
  beforeUnmount() {},
  methods: {
    formatText(text) {
      // 使用正则表达式替换文本中的 \n 为 <br> 标签
      return text?.replace(/\n/g, '<br>')
    },
    goReply() {
      this.$router.push('/reply')
    },
    handleRelease() {
      this.$api.deleteBottle(this.bottleInfo.id).then((res) => {
        if (res.code === 0) {
          bottleListRef.value = bottleListRef.value.filter(
            (item) => item?.id !== this.bottleInfo.id
          )
          this.$nextTick(() => {
            showToast('Release success')
            this.$router.push('/bottles')
          })
          return
        }
        showToast(res?.message)
      })
    },
    readMessageFn() {
      this.$api.readMessage(this.bottleInfo.id).then((res) => {
        if (res.code === 0) {
          return
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  background: linear-gradient(to bottom, #acecf9, #d6fdfa);
  height: 31vh;
}
.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  width: 90%;
  margin: 1rem auto;
  margin-bottom: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  img {
    width: 1rem;
    height: 1rem;
  }
  .more {
    margin-left: 0.5rem;
    width: 1rem;
    height: 0.25rem;
  }
}
.bottomText {
  height: 4rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 14.45rem;
    height: 2.35rem;
    font-size: 0.85rem;
    font-weight: bold;
    color: #fff;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 0.3rem;
    }
  }
  .release {
    background-color: #f75d7a;
    img {
      width: 0.65rem;
      height: 0.7rem;
    }
  }
  .reply {
    background-color: #39cce9;
    img {
      width: 0.85rem;
      height: 0.6rem;
    }
  }
}
.editBack {
  width: 17rem;
  height: calc(100% - 4rem - 4rem);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: start;
  position: relative;
  .textImg {
    position: relative;
    width: 200px;
    margin-top: 2rem;

    .deleteImg {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 2rem;
      height: 2rem;
    }

    img {
      width: 200px;
      border-radius: 0.5rem;
    }
  }

  .avatar {
    position: absolute;
    top: -1.8rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    img {
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 100%;
    }
  }
  .bottleInfo {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    .infoTop {
      display: flex;
      justify-content: center;
      .username {
        font-size: 0.5rem;
        width: 4.65rem;
        height: 1rem;
        line-height: 1rem;
        font-weight: bold;
        background: #ffffff;
        border-radius: 0.5rem;
      }
      .userage {
        display: flex;
        align-items: center;
        font-size: 0.5rem;
        img {
          width: 0.7rem;
          height: 0.7rem;
          margin: 0 0.3rem;
        }
      }
    }
    .infoBottom {
      font-size: 0.5rem;
      margin: 0.5rem 0;
      span {
        margin: 0 0.15rem;
      }
    }
  }
  .textContent {
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0 1rem;
    text-align: start;
    height: 75%;
    overflow: scroll;
    word-break: break-word;
  }
}
.moreModel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .topTip {
    top: 0.5rem;
    width: 4rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    background-color: #dddddd;
    border-radius: 0.5rem;
  }
  .moreOff {
    z-index: 2005;
    position: absolute;
    right: 0.5rem;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 1.7rem;
      height: 1.7rem;
    }
  }
  .moreBtn {
    background-color: #39cce9;
    width: 11.6rem;
    height: 2.9rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }
}
.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    width: 4.25rem;
    height: 4.25rem;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
</style>
