<template>
  <div class="friend_page bgSea">
    <div class="top">
      <div class="title">Invite</div>
    </div>
    <div style="margin-top: 3.5rem">
      <div
        v-for="(item, index) in achievementList"
        :key="index"
        class="inviteTitle"
      >
        <div class="inviteName">{{ item?.title }}</div>
        <div class="invitePrice">
          <img :src="imgs.coin" alt="" />+{{ item?.award }}
        </div>
      </div>
    </div>

    <div class="friendCount">
      <div style="margin-right: 1rem">
        <img :src="imgs.or_friend" alt="" />{{ commonFriendCount }}
      </div>
      <div><img :src="imgs.pr_friend" alt="" />{{ premiumFriendCount }}</div>
    </div>
    <div class="list">
      <div
        class="listItem"
        v-for="(item, index) in inviteFirendList"
        :key="index"
      >
        <div class="itemImg">
          <img :src="imgUrl(item)" alt="" />
        </div>
        <div class="itemInfo">
          <div class="infoLeft">
            <div class="itemSend">
              {{ item?.payload?.nickname || 'Friend' }}
            </div>
            <div class="itemTime">
              <img :src="imgs.coin" alt="" />+{{
                getRewardNum(item?.is_premium)
              }}
            </div>
          </div>
          <div class="infoRight flex" @click="goTg(item)">
            <img :src="imgs.btn_chat" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="inviteBtn inviteBtn-am" @click="invite()">Invited a friend</div>
  </div>
</template>

<script>
import flag from 'vue-country-flag-next'
import { showToast } from 'vant'
import { getAvatar } from '@/config/common/dic'
import {
  shareDicListRef,
  shareListRef,
  shareTotalNumRef,
} from '@/config/common/total'
export default {
  components: { flag },
  data() {
    return {
      imgs: this.$store.state.sourceMsg,
      friendList: [
        // gender:0-man，1-woman, 3:all
        { name: 'Weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'Lily', gender: 1, age: 28, country: 'US', code: 'us' },
        { name: 'Hhhhh', gender: 1, age: 30, country: 'Japan', code: 'jp' },
        {
          name: 'changeA1111',
          gender: 0,
          age: 19,
          country: 'France',
          code: 'fr',
        },
        {
          name: 'firstName',
          gender: 0,
          age: 24,
          country: 'France',
          code: 'fr',
        },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
        { name: 'weile', gender: 0, age: 24, country: 'France', code: 'fr' },
      ],
      achievementList: [],
      inviteFirendList: [],
      btnTimer: null,
      totalInfo: {},
    }
  },
  computed: {
    premiumFriendCount() {
      return this.totalInfo?.premium_total || 0
    },
    commonFriendCount() {
      return this.totalInfo?.common_total || 0
    },
  },
  created() {
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/' })
    })
    Telegram.WebApp.setHeaderColor('#fff')
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F5F6'
    this.achievementList = shareDicListRef.value
    this.inviteFirendList = shareListRef.value
    this.totalInfo = shareTotalNumRef.value
    this.getAchievementTask()
    this.getTasksInviteFriendListFn()
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
    clearInterval(this.btnTimer)
  },
  methods: {
    imgUrl(item) {
      return item?.is_premium
        ? '/imgs/friend/pr_friend.png'
        : '/imgs/friend/or_friend.png'
    },
    // 跳转到tg聊天
    goTg(item) {
      Telegram.WebApp.openTelegramLink(`https://t.me/${item.tgid}`)
    },
    // 邀请按钮
    invite() {
      let id = this.$g.getStorage('userId')
      this.$g.invite(id)
    },
    getAchievementTask() {
      if (shareDicListRef.value.length) {
        this.achievementList = shareDicListRef.value
        return
      }
      this.$api.getInviteFriendTasks().then((res) => {
        if (res.code === 0) {
          this.achievementList = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            return item
          })
          shareDicListRef.value = this.achievementList
          console.log(this.achievementList, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    getTasksInviteFriendListFn() {
      this.$api.getTasksInviteFriend().then((res) => {
        if (res.code === 0) {
          this.inviteFirendList = res?.data?.data.map((item) => {
            item.avatar = getAvatar(item.tgid)
            return item
          })
          shareListRef.value = this.inviteFirendList
          this.totalInfo = res?.data?.total
          shareTotalNumRef.value = res?.data?.total
          return
        }
        return showToast(res?.message)
      })
    },
    getRewardNum(isPremium) {
      if (isPremium) {
        return this.achievementList[1]?.award
      }
      return this.achievementList[0]?.award
    },
  },
}
</script>

<style scoped lang="scss">
.inviteBtn {
  background-color: #38cbe9;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  color: white;
  position: fixed;
  bottom: 4.5rem;
  left: 50%;
  transition: all 1s;
}
.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 2rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
}
.inviteTitle {
  width: 85%;
  border-radius: 0.5rem;
  background-color: #dcf5fa;
  margin: 0.5rem auto;
  padding: 0.25rem 0.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inviteName {
    font-size: 0.85rem;
    font-weight: 700;
  }
  .invitePrice {
    color: #39cce9;
    display: flex;
    align-items: center;
    /* margin-left: 1.5rem; */
    img {
      width: 1.25rem;
      margin: 0 0.15rem;
    }
  }
}
.friendCount {
  margin-left: 1.25rem;
  display: flex;
  justify-content: start;
  color: #22b8d6;
  img {
    height: 1.5rem;
    margin-right: 0.5rem;
  }
}
.list {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 7rem;
  .listItem {
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    margin-bottom: 0.5rem;
    .itemImg {
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 0.5rem;
      background: #b2eff9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      img {
        width: 1.55rem;
        height: 2.1rem;
      }
    }
    .itemInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      font-size: 0.7rem;
      .infoLeft {
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .itemTime {
          color: #22b8d6;
          font-weight: bold;
          height: 1rem;
          display: flex;
          align-items: center;
          img {
            width: 1rem;
            margin-right: 0.2rem;
          }
          .flag {
            margin-bottom: 1px;
            border-radius: 0.25rem;
            box-shadow: 0rem 0rem 0.25rem gray;
          }
        }
      }
      .infoRight {
        img {
          width: 1.75rem;
        }
      }
    }
  }
}

.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
.inviteBtn-am {
  transform: scale(1) translateX(-50%);
  transition: transform 0.6s ease-in-out;
  animation: btnScale 1.2s infinite alternate;
}

@keyframes btnScale {
  0% {
    transform: scale(1) translateX(-50%);
  }
  100% {
    transform: scale(0.95) translateX(-50%);
  }
}
</style>
