import { createApp } from 'vue'
import router from './router'
import store from './store'
import { Locale } from 'vant'
import Vant from 'vant'
import 'vant/lib/index.css'
import enUS from 'vant/es/locale/lang/en-US'
import $g from './config/common'
Locale.use('en-US', enUS)
import $api from './api/api'
import Vconsole from 'vconsole'
import VueLuckyCanvas from '@lucky-canvas/vue'
// 移动端控制台
const vConsole = new Vconsole()

import './style.css'
import App from './App.vue'
let app = createApp(App)
app.config.globalProperties.$g = $g
app.config.globalProperties.$api = $api
app.config.globalProperties.$config = import.meta.env
window.document.title = import.meta.env.VITE_APP_NAME
// 转盘
app.use(VueLuckyCanvas)
app.use(Vant)
app.use(router).use(store).mount('#app')
