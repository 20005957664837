// import a01 from '../assets/img/loadingNew/01.png';
let resource = {
  first: [
    // 主页 tab
    {
      name: 'icon_achievement',
      path: '/imgs/home/icon_achievement.png',
    },
    {
      name: 'icon_achievement_full',
      path: '/imgs/home/icon_achievement_full.png',
    },
    {
      name: 'icon_bottle',
      path: '/imgs/home/icon_bottle.png',
    },
    {
      name: 'icon_bottle_full',
      path: '/imgs/home/icon_bottle_full.png',
    },
    {
      name: 'icon_friend',
      path: '/imgs/home/icon_friend.png',
    },
    {
      name: 'icon_friend_full',
      path: '/imgs/home/icon_friend_full.png',
    },
    {
      name: 'icon_Mission',
      path: '/imgs/home/icon_Mission.png',
    },
    {
      name: 'icon_Mission_full',
      path: '/imgs/home/icon_Mission_full.png',
    },
    {
      name: 'icon_profile',
      path: '/imgs/home/icon_profile.png',
    },
    {
      name: 'icon_profile_full',
      path: '/imgs/home/icon_profile_full.png',
    },
    // 顶部
    {
      name: 'add',
      path: '/imgs/home/add.png',
    },
    {
      name: 'bottle',
      path: '/imgs/home/bottle.png',
    },
    {
      name: 'coin',
      path: '/imgs/home/coin.png',
    },
    {
      name: 'notify',
      path: '/imgs/home/notify.png',
    },
    {
      name: 'spin',
      path: '/imgs/home/spin.png',
    },
    {
      name: 'cloud',
      path: '/imgs/home/cloud.png',
    },
    {
      name: 'light',
      path: '/imgs/home/light.png',
    },
    {
      name: 'sign1',
      path: '/imgs/home/sign1.png',
    },
    {
      name: 'sign2',
      path: '/imgs/home/sign2.png',
    },
    {
      name: 'treasure',
      path: '/imgs/home/treasure.png',
    },
    // 岛
    {
      name: 'island',
      path: '/imgs/home/island.png',
    },
    {
      name: 'people',
      path: '/imgs/home/people.png',
    },
    {
      name: 'tree1',
      path: '/imgs/home/tree1.png',
    },
    {
      name: 'tree2',
      path: '/imgs/home/tree2.png',
    },
    {
      name: 'tree3',
      path: '/imgs/home/tree3.png',
    },
    // 注册页
    {
      name: 'choose',
      path: '/imgs/register/choose.png',
    },
    {
      name: 'select',
      path: '/imgs/register/select.png',
    },
    {
      name: 'selected',
      path: '/imgs/register/selected.png',
    },
    // 编辑瓶子页
    {
      name: 'sendBottle',
      path: '/imgs/sendBottle/sendBottle.png',
    },
    {
      name: 'sendBottle_full',
      path: '/imgs/sendBottle/sendBottle_full.png',
    },
    {
      name: 'fontFamily',
      path: '/imgs/sendBottle/fontFamily.png',
    },
    {
      name: 'off',
      path: '/imgs/sendBottle/off.png',
    },
    {
      name: 'font1',
      path: '/imgs/sendBottle/font1.png',
    },
    {
      name: 'font2',
      path: '/imgs/sendBottle/font2.png',
    },
    {
      name: 'font3',
      path: '/imgs/sendBottle/font3.png',
    },
    {
      name: 'font4',
      path: '/imgs/sendBottle/font4.png',
    },
    {
      name: 'font5',
      path: '/imgs/sendBottle/font5.png',
    },
    {
      name: 'men',
      path: '/imgs/sendBottle/men.png',
    },
    {
      name: 'men_full',
      path: '/imgs/sendBottle/men_full.png',
    },
    {
      name: 'women',
      path: '/imgs/sendBottle/women.png',
    },
    {
      name: 'women_full',
      path: '/imgs/sendBottle/women_full.png',
    },
    {
      name: 'photo',
      path: '/imgs/sendBottle/photo.png',
    },
    // 捞到的瓶子
    {
      name: 'more',
      path: '/imgs/reBottle/more.png',
    },
    {
      name: 'copy_full',
      path: '/imgs/reBottle/copy_full.png',
    },
    {
      name: 'send',
      path: '/imgs/reBottle/send.png',
    },
    {
      name: 'release',
      path: '/imgs/reBottle/release.png',
    },
    {
      name: 'reply',
      path: '/imgs/reBottle/reply.png',
    },
    {
      name: 'translate',
      path: '/imgs/reBottle/translate.png',
    },
    {
      name: 'block',
      path: '/imgs/reBottle/block.png',
    },
    {
      name: 'block_full',
      path: '/imgs/reBottle/block_full.png',
    },
    {
      name: 'copy',
      path: '/imgs/reBottle/copy.png',
    },
    {
      name: 'report',
      path: '/imgs/reBottle/report.png',
    },
    {
      name: 'report_full',
      path: '/imgs/reBottle/report_full.png',
    },
    // 信纸
    {
      name: 'paper1',
      path: '/imgs/paper/paper1.png',
    },
    {
      name: 'paper2',
      path: '/imgs/paper/paper2.png',
    },
    {
      name: 'paper3',
      path: '/imgs/paper/paper3.png',
    },
    {
      name: 'paper4',
      path: '/imgs/paper/paper4.png',
    },
    {
      name: 'paper5',
      path: '/imgs/paper/paper5.png',
    },
    // loading
    {
      name: 'boat',
      path: '/imgs/loading/boat.png',
    },
    {
      name: 'driftsea',
      path: '/imgs/loading/driftsea.png',
    },
    {
      name: 'island_logo',
      path: '/imgs/loading/island_logo.png',
    },
    // 好友页面
    {
      name: 'delete',
      path: '/imgs/friend/delete.png',
    },
    {
      name: 'delete_btn',
      path: '/imgs/friend/delete_btn.png',
    },
    {
      name: 'point',
      path: '/imgs/friend/point.png',
    },
    // 日常任务
    {
      name: 'activity1',
      path: '/imgs/task/activity1.png',
    },
    {
      name: 'activity2',
      path: '/imgs/task/activity2.png',
    },
    {
      name: 'chest',
      path: '/imgs/task/chest.png',
    },
    {
      name: 'debris',
      path: '/imgs/task/debris.png',
    },
    {
      name: 'finish',
      path: '/imgs/task/finish.png',
    },
    {
      name: 'group',
      path: '/imgs/task/group.png',
    },
    {
      name: 'mission_coin',
      path: '/imgs/task/mission_coin.png',
    },
    {
      name: 'off1',
      path: '/imgs/task/off1.png',
    },
    {
      name: 'reply1',
      path: '/imgs/task/reply1.png',
    },
    {
      name: 'TAPPPark',
      path: '/imgs/task/TAPPPark.png',
    },
    {
      name: 'TG',
      path: '/imgs/task/TG.png',
    },
    {
      name: 'throwbottle',
      path: '/imgs/task/throwbottle.png',
    },
    {
      name: 'wallet1',
      path: '/imgs/task/wallet1.png',
    },
    {
      name: 'wallet2',
      path: '/imgs/task/wallet2.png',
    },
    // 个人资料
    {
      name: 'edit',
      path: '/imgs/profile/edit.png',
    },
    {
      name: 'title_front',
      path: '/imgs/profile/title_front.png',
    },
    // 任务宝箱奖励
    {
      name: 'box1',
      path: '/imgs/box/box1.png',
    },
    {
      name: 'box1_open',
      path: '/imgs/box/box1_open.png',
    },
    {
      name: 'box2',
      path: '/imgs/box/box2.png',
    },
    {
      name: 'box2_open',
      path: '/imgs/box/box2_open.png',
    },
    {
      name: 'box3',
      path: '/imgs/box/box3.png',
    },
    {
      name: 'box3_open',
      path: '/imgs/box/box3_open.png',
    },
    {
      name: 'conch',
      path: '/imgs/box/conch.png',
    },
    {
      name: 'fish1',
      path: '/imgs/box/fish1.png',
    },
    {
      name: 'fish2',
      path: '/imgs/box/fish2.png',
    },
    {
      name: 'hermit',
      path: '/imgs/box/hermit.png',
    },
    {
      name: 'iring',
      path: '/imgs/box/iring.png',
    },
    {
      name: 'necklace',
      path: '/imgs/box/necklace.png',
    },
    {
      name: 'octopus',
      path: '/imgs/box/octopus.png',
    },
    {
      name: 'pearloyster',
      path: '/imgs/box/pearloyster.png',
    },
    {
      name: 'rum',
      path: '/imgs/box/rum.png',
    },
    // send瓶子页面
    {
      name: 'sendBg',
      path: '/imgs/send/sendBg.png',
    },
    {
      name: 'splash',
      path: '/imgs/send/splash.png',
    },
    // 商店
    {
      name: 'buy_bottle1',
      path: '/imgs/shopping/buy_bottle1.png',
    },
    {
      name: 'buy_bottle2',
      path: '/imgs/shopping/buy_bottle2.png',
    },
    {
      name: 'buy_bottle3',
      path: '/imgs/shopping/buy_bottle3.png',
    },
    {
      name: 'buy_coin1',
      path: '/imgs/shopping/buy_coin1.png',
    },
    {
      name: 'buy_coin2',
      path: '/imgs/shopping/buy_coin2.png',
    },
    {
      name: 'buy_coin3',
      path: '/imgs/shopping/buy_coin3.png',
    },
    {
      name: 'buy_coin4',
      path: '/imgs/shopping/buy_coin4.png',
    },
    {
      name: 'title',
      path: '/imgs/shopping/title.png',
    },
    {
      name: 'ton',
      path: '/imgs/shopping/ton.png',
    },
    {
      name: 'wallet',
      path: '/imgs/shopping/wallet.png',
    },
    {
      name: 'wallet_null',
      path: '/imgs/shopping/wallet_null.png',
    },
    // 转盘
    {
      name: 'decoration_btn',
      path: '/imgs/spin/decoration_btn.png',
    },
    {
      name: 'start',
      path: '/imgs/spin/start.png',
    },
    {
      name: 'turntable',
      path: '/imgs/spin/turntable.png',
    },
    // 瓶子类型
    {
      name: 'directedBottle',
      path: '/imgs/bottleType/directedBottle.png',
    },
    {
      name: 'directedBottle_big',
      path: '/imgs/bottleType/directedBottle_big.png',
    },
    {
      name: 'directedBottle_null',
      path: '/imgs/bottleType/directedBottle_null.png',
    },
    {
      name: 'friendBottle',
      path: '/imgs/bottleType/friendBottle.png',
    },
    {
      name: 'friendBottle_big',
      path: '/imgs/bottleType/friendBottle_big.png',
    },
    {
      name: 'friendBottle_null',
      path: '/imgs/bottleType/friendBottle_null.png',
    },
    {
      name: 'fishingrod',
      path: '/imgs/fishingrod.png',
    },
    {
      name: 'line',
      path: '/imgs/line.png',
    },
    {
      name: 'stars',
      path: '/imgs/stars.png',
    },
    {
      name: 'btn_chat',
      path: '/imgs/friend/btn_chat.png',
    },
    {
      name: 'or_friend',
      path: '/imgs/friend/or_friend.png',
    },
    {
      name: 'pr_friend',
      path: '/imgs/friend/pr_friend.png',
    },
  ],
  second: {
    name: 'AllOrderBtn',
    path: '/imgs/AllOrderBtn.png',
  },
}

export default resource
