import $store from '@/store/index'
import imageCompression from 'browser-image-compression'
import { showToast } from 'vant'
import md5 from 'js-md5'
import $api from '@/api/api'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export function generateUniqueString() {
  // 生成UUID
  const uuid = uuidv4()

  // 通过去掉UUID中的 '-' 或其他方式来确保它是一个唯一的字符串
  const uniqueString = uuid.replace(/-/g, '')

  return uniqueString
}

console.log(generateUniqueString(), 88888888881888) // 输出唯一字符串

const defaultOptions = {
  maxSizeMB: 0.8,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
}
// 封装的图片压缩函数
export async function compressImage(
  file,
  options = defaultOptions,
  exceed = 50
) {
  try {
    if (file.size > 1024 * 1024 * exceed) {
      showToast(`The file size cannot exceed ${exceed}MB`)
      return null
    }

    const compressedFile = await imageCompression(file, options)
    console.log('Original file size:', file.size / 1024 / 1024, 'MB')
    console.log(
      'Compressed file size:',
      compressedFile.size / 1024 / 1024,
      'MB'
    )

    return compressedFile
  } catch (error) {
    console.error('Error during image compression:', error)
    return null
  }
}

// 发帖字体
const fontFamilyListFN = () => {
  return [
    {
      fontFamilyId: 0,
      id: 0,
      name: 'Simple feather',
      fontFamily: 'Simple feather',
      count: 0,
      img: $store.state.sourceMsg.font1,
    },
    {
      fontFamilyId: 1,
      id: 1,
      name: 'Axure Handwriting',
      fontFamily: 'Axure Handwriting',
      count: 1,
      img: $store.state.sourceMsg.font2,
    },
    {
      fontFamilyId: 2,
      id: 2,
      name: 'soulhand writing',
      fontFamily: 'soulhand writing',
      count: 1,
      img: $store.state.sourceMsg.font3,
    },
    {
      fontFamilyId: 3,
      id: 3,
      name: 'Playlist Script',
      fontFamily: 'Playlist Script',
      count: 1,
      img: $store.state.sourceMsg.font4,
    },
    {
      fontFamilyId: 4,
      id: 4,
      name: 'While Paper',
      fontFamily: 'While Paper',
      count: 1,
      img: $store.state.sourceMsg.font5,
    },
  ]
}
// 发帖皮肤
const paperListFN = () => {
  return [
    {
      paperId: 0,
      id: 0,
      name: 'Classic',
      count: 0,
      img: $store.state.sourceMsg.paper1,
    },
    {
      paperId: 1,
      id: 1,
      name: 'Rolled paper',
      count: 1,
      img: $store.state.sourceMsg.paper2,
    },
    {
      paperId: 2,
      id: 2,
      name: 'Pink paper',
      count: 1,
      img: $store.state.sourceMsg.paper3,
    },
    {
      paperId: 3,
      id: 3,
      name: 'old paper',
      count: 1,
      img: $store.state.sourceMsg.paper4,
    },
    {
      paperId: 4,
      id: 4,
      name: 'White paper',
      count: 1,
      img: $store.state.sourceMsg.paper5,
    },
  ]
}
export const paperList = () => paperListFN()
export const fontFamilyList = () => fontFamilyListFN()

export function formatDate(dateString) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()

  let daySuffix
  if (day % 10 === 1 && day !== 11) {
    daySuffix = 'st'
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = 'nd'
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = 'rd'
  } else {
    daySuffix = 'th'
  }

  return `${month} ${day}${daySuffix} ${year}`
}

async function sendMultipartRequest(url, file) {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post(url, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data', // 设置请求头
      },
    })
    return response?.data?.result?.variants?.[0]
  } catch (error) {
    return null
  }
}

// 下载前获得上传地址
export const getUploadUrl = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const selectedFile = await compressImage(file)
      console.log(selectedFile, 'compress->>>>>>')
      const res = await $api.getUploadUrl()
      console.log(res, 'remote uploadurl')
      if (res.code === 0 && res?.data?.uploadURL) {
        const result = await sendMultipartRequest(
          res?.data?.uploadURL,
          selectedFile
        )
        console.log(result, 'result url')
        result && resolve(result)
        !result && reject('')
      }
    } catch (error) {
      console.log(error, 4348898)
    }
  })
}

export const getAvatar = (avatar) => {
  try {
    let avatarMd = md5(avatar + '')
    return `https://image.googlescdn.com/${avatarMd}`
  } catch (error) {
    console.log(error, 'error')
  }
}
