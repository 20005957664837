import { createStore } from 'vuex'
export default createStore({
  state: {
    sourceMsg:{},
    walletInfo:{},
    tabName:'',
    disableScroll:false,
  },
  mutations: {
    SAVE_RESOURCE(state,msg){
      state.sourceMsg = msg;
    },
    CHANGE_DISABLE_SCROLL(state,isDisable){
      state.disableScroll = isDisable;
    },
    CHANGE_WALLET_INFO(state,walletInfo){
      state.walletInfo = walletInfo;
    },
    CHANGE_TAB_NAME(state,name){
      state.tabName = name;
    }
  },
  actions: {
    saveResource({ commit },imgMsg){
      commit('SAVE_RESOURCE',imgMsg)
    },
    changeDisableScrollEvent(event,isDisable=false){
      event.commit('CHANGE_DISABLE_SCROLL',isDisable)
    },
    changeWalletInfo({commit},walletInfo){
      console.log('walletInfo=======',walletInfo)
      commit('CHANGE_WALLET_INFO',walletInfo)
    },
    changeTabName({commit},name){
      commit('CHANGE_TAB_NAME',name)
    },
  },
  modules: {
  }
})
