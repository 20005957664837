
<template>
   <div class="flex start_content bgSea">
    <div class="box">
      <div class="bottleTitle">
        <img src="../assets/img/loding/driftsea.png" alt="">
      </div>
      <div class="bottleIsland">
        <img src="../assets/img/loding/island_logo.png" alt="">
      </div>
      <div class="progress_bar">
          <div class="progress_point"></div>
          <div class="progress_point1" :style="{ width : step1 + '%' }"></div>
          <div class="boat" ref="boat" :style="{ left : step/1.3 + '%' }"></div>
      </div>      
    </div>
   </div>
</template>
<script>
export default {
  components: {},
  props:{
    step:{
        type:Number,
        default:0
    },
    step1:{
        type:Number,
        default:0
    }
  },
  data() {
    return {
      total:30,
    }
  },
  created(){
   
    
  },
  mounted(){


  }
}
</script>
<style scoped lang="scss">
.start_content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3000;
    background-color: #7ED4E5;
    display: flex;
    justify-content: center;
    .box{
      height: 70vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .bottleTitle{
        height: 5rem;
        width: 12rem;
        img{
          height: 5rem;
          width: 12rem;
        }
      }
      .bottleIsland{
        width: 15rem;
        height: 7.5rem;
        img{
          width: 15rem;
          height: 7.5rem;
        }
      }      
    }

}
.progress_bar{
    width: 13.25rem;
    height: 1.1rem;
    box-shadow: 0 0 10px #ccc;
    border-radius: 0.5rem;
    padding: 0.1rem;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    .progress_point{
      background: repeating-linear-gradient(-60deg, #6abeff, #6abeff 3%, #36a0f2 3%, #36a0f2 6%);
      animation: progressbar 3600s infinite;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      transition: width 1s;
    }
    .progress_point1{
      position: absolute;
      top: 0;
      right: 0;
      background-color: #fff;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      transition: width 1s;
    }
    .boat{
      transition: left 1s;
      position: absolute;
      left: -1rem;
      bottom: 0px;
      height: 3rem;
      width: 4.25rem;
      background: url('../assets/img/loding/boat.png');
      background-size: 100% 100%;
      z-index: 3;
      animation: truck 0.5s linear infinite;
    }
}
@keyframes progressbar {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 250000px 0;
  }
}

</style>
