import api from '../config/api'
let $config = import.meta.env
var baseURL = $config.VITE_BASE_URL
let $api = {}
$api.postAuth = (params) => {
  return api.post('/portal/index/auth', params)
}
$api.getUpdataMsg = (params) => {
  return api.get('/update', params)
}
// 登录接口
$api.login = (params) => {
  return api.post(`${baseURL}api/user/login`, params)
}
// 注册接口
$api.register = (params) => {
  return api.post(`${baseURL}api/user/register`, params)
}
// 获取道具列表接口
$api.getItem = () => {
  return api.get(`${baseURL}api/items`)
}
// 获取用户接口
$api.getUserInfo = () => {
  return api.get(`${baseURL}api/user`)
}
// 获取用户背包接口
$api.getUserBag = () => {
  return api.get(`${baseURL}api/user/items`)
}
// 获取初始参数
$api.getInitParams = () => {
  return api.get(`${baseURL}api/init`)
}
/**
 * 漂流瓶
 * **/

// 扔瓶子
$api.throwBottle = (params) => {
  return api.post(`${baseURL}api/bottle`, params)
}
// 捞瓶子
$api.poolBottle = (params) => {
  return api.get(`${baseURL}api/bottle/pool`)
}
// 重新丢回池子
$api.poolAgainBottle = (params) => {
  return api.put(`${baseURL}api/bottle/pool/${params}`)
}
// 回复瓶子消息
$api.replyBottle = (params) => {
  return api.post(`${baseURL}api/bottle/message`, params)
}
// 回复瓶子列表
$api.getReplyBottle = (page, size, type = '') => {
  return api.get(
    `${baseURL}api/bottle/message?page=${page}&size=${size}&type=${type}`
  )
}
// 用瓶子查消息详情
$api.getReplyInfo = (bottleId) => {
  return api.get(`${baseURL}api/bottle/message/${bottleId}`)
}
// 消息已读
$api.readMessage = (params) => {
  return api.put(`${baseURL}api/bottle/message/${params}/set-is-read`)
}
// 获取瓶子列表 /api/bottle?page=1&size=10
$api.getBottleList = (page, size) => {
  return api.get(`${baseURL}api/bottle?page=${page}&size=${size}`)
}

// 回复列表 /api/bottle?page=1&size=10
$api.getMessageList = (page, size, type = '') => {
  return api.get(
    `${baseURL}api/bottle/message?page=${page}&size=${size}&type=${type}`
  )
}
// 删除瓶子
$api.deleteBottle = (bottleId) => {
  return api.delete(`${baseURL}api/bottle/${bottleId}`)
}

// 举报瓶子
$api.reportBottle = (bottle_id) => {
  return api.put(`${baseURL}api/bottle/report/${bottle_id}`)
}

// 获取最新回复消息
$api.getFirstMessage = () => {
  return api.get(`${baseURL}api/bottle/message/first`)
}

/**
 * 好友模块
 * **/

// 好友列表
$api.getFriendsList = (page, size) => {
  return api.get(`${baseURL}api/friend?page=${page}&size=${size}`)
}
// 添加好友
$api.addFriend = (params) => {
  return api.post(`${baseURL}api/friend`, params)
}
// 删除好友
$api.deleteFriend = (params) => {
  return api.delete(`${baseURL}api/friend/${params}`)
}
// 拉黑用户
$api.blockFriend = (params) => {
  return api.post(`${baseURL}api/friend-block`, params)
}

/**
 * 个人主页
 * **/

// 修改个人信息
$api.editUser = (params) => {
  return api.put(`${baseURL}api/user`, params)
}

// 获取用户已完成成就
$api.getUserAchievement = (tgid) => {
  return api.get(`${baseURL}api/tasks/achievement/${tgid}`)
}

/**
 * 任务模块
 * **/
// 成就任务
$api.achievementTask = () => {
  return api.get(`${baseURL}api/tasks/achievement`)
}
// 领取奖励
$api.getReward = (params) => {
  return api.get(`${baseURL}api/tasks/${params}/get-rewards`)
}

// 邀请好友任务
$api.getInviteFriendTasks = () => {
  return api.get(`${baseURL}api/tasks/invite-friend`)
}
// 邀请好友列表
$api.getTasksInviteFriend = (page = 1, size = 10) => {
  return api.get(
    `${baseURL}api/tasks/invite-friend-log?page=${page}&size=${size}`
  )
}

// 上传地址获取
$api.getUploadUrl = () => {
  return api.get(`${baseURL}api/common/upload`)
}

/***
 * 商城
 * */

// 连接钱包
$api.connectwallet = () => {
  return api.get(`${baseURL}api/common/upload`)
}

// 获取商品列表
$api.getGoodsList = () => {
  return api.get(`${baseURL}api/shop-goods`)
}

// 金币请求或者跳转地址
$api.buyGood_Ajax = (params) => {
  return api.post(`${baseURL}api/shop-goods/buy`, params)
}
// 查询订单支付状态
$api.checkOrderStatus_Ajax = (params) => {
  return api.get(`${baseURL}api/order-and-payment-sdk/is-pay-success/${params}`)
}

export default $api
